/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGenerationJob = /* GraphQL */ `
  mutation CreateGenerationJob($generationJob: GenerationJobInput!) {
    createGenerationJob(generationJob: $generationJob) {
      uuid
      type
      params
      status
      note
      initImageURLs
      uploadImageURLs
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGenerationJob = /* GraphQL */ `
  mutation UpdateGenerationJob(
    $updateGenerationJob: UpdateGenerationJobInput!
  ) {
    updateGenerationJob(updateGenerationJob: $updateGenerationJob) {
      uuid
      type
      params
      status
      note
      initImageURLs
      uploadImageURLs
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGenerationJob = /* GraphQL */ `
  mutation DeleteGenerationJob($id: CollectionID!) {
    deleteGenerationJob(id: $id)
  }
`;
export const startGeneration = /* GraphQL */ `
  mutation StartGeneration($id: CollectionID!) {
    startGeneration(id: $id) {
      uuid
      type
      params
      status
      note
      initImageURLs
      uploadImageURLs
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const cancelGeneration = /* GraphQL */ `
  mutation CancelGeneration($id: CollectionID!) {
    cancelGeneration(id: $id)
  }
`;
export const createTrainingJob = /* GraphQL */ `
  mutation CreateTrainingJob($trainingJob: TrainingJobInput!) {
    createTrainingJob(trainingJob: $trainingJob) {
      uuid
      type
      params
      status
      note
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
      uploadImageURLs
      numTrainingImages
    }
  }
`;
export const updateTrainingJob = /* GraphQL */ `
  mutation UpdateTrainingJob($updateTrainingJob: UpdateTrainingJobInput!) {
    updateTrainingJob(updateTrainingJob: $updateTrainingJob) {
      uuid
      type
      params
      status
      note
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
      uploadImageURLs
      numTrainingImages
    }
  }
`;
export const deleteTrainingJob = /* GraphQL */ `
  mutation DeleteTrainingJob($id: CollectionID!) {
    deleteTrainingJob(id: $id)
  }
`;
export const startTraining = /* GraphQL */ `
  mutation StartTraining($id: CollectionID!) {
    startTraining(id: $id) {
      uuid
      type
      params
      status
      note
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
      uploadImageURLs
      numTrainingImages
    }
  }
`;
export const createUserModel = /* GraphQL */ `
  mutation CreateUserModel($model: UserModelInput!) {
    createUserModel(model: $model) {
      uuid
      baseModel {
        uuid
        name
        description
        thumbnail
        createdAt
        updatedAt
      }
      name
      description
      thumbnail
      params
      likes
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserModel = /* GraphQL */ `
  mutation UpdateUserModel($updateModel: UpdateUserModelInput!) {
    updateUserModel(updateModel: $updateModel) {
      uuid
      baseModel {
        uuid
        name
        description
        thumbnail
        createdAt
        updatedAt
      }
      name
      description
      thumbnail
      params
      likes
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserModel = /* GraphQL */ `
  mutation DeleteUserModel($id: CollectionID!) {
    deleteUserModel(id: $id)
  }
`;
export const createBaseModel = /* GraphQL */ `
  mutation CreateBaseModel($baseModel: BaseModelInput!) {
    createBaseModel(baseModel: $baseModel) {
      uuid
      name
      description
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const updateBaseModel = /* GraphQL */ `
  mutation UpdateBaseModel($updateBaseModel: UpdateBaseModelInput!) {
    updateBaseModel(updateBaseModel: $updateBaseModel) {
      uuid
      name
      description
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const deleteBaseModel = /* GraphQL */ `
  mutation DeleteBaseModel($id: String!) {
    deleteBaseModel(id: $id)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      sub
      name
      picture
      follows
      images {
        totalCount
      }
      userModels {
        totalCount
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($updateUser: UpdateUserInput!) {
    updateUser(updateUser: $updateUser) {
      sub
      name
      picture
      follows
      images {
        totalCount
      }
      userModels {
        totalCount
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($userSub: String!) {
    deleteUser(userSub: $userSub)
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage($image: ImageInput!) {
    createImage(image: $image) {
      uuid
      jobId
      name
      url
      params
      likes
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage($updateImage: UpdateImageInput!) {
    updateImage(updateImage: $updateImage) {
      uuid
      jobId
      name
      url
      params
      likes
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage($id: CollectionID!) {
    deleteImage(id: $id)
  }
`;
export const deleteImages = /* GraphQL */ `
  mutation DeleteImages($ids: [CollectionID!]!) {
    deleteImages(ids: $ids)
  }
`;
