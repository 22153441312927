import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

export default function Link({
  href,
  isExternal,
  ...restProps
}: {
  [x: string]: any;
  isExternal?: boolean;
  href?: string;
}) {
  return (
    // TODO: Don't opt out from type checking once `ChakraLink` gets fixed
    <ChakraLink
      {...(isExternal
        ? { href, isExternal, to: null }
        : { as: GatsbyLink, to: href })}
      {...restProps}
    />
  );
}
