import { wrapPageElement as wrap } from './root-wrapper';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './src/aws-exports';
// import { CdkBackendStack as prodStack } from './src/cdk-exports.json';
// import { redreamTest as testStack } from './src/cdk-exports-test.json';
// import { localStack } from './src/cdk-exports-local.json';
import { getEnvConfig } from './src/utils/configUtils';

const awsDefaults = {
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: false,
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id:
    'us-west-2:a10e7e70-ab96-4a01-9563-620f7cecb31a',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_V1tILs4dv', //'us-west-2_KVZpn4mm3',
  aws_user_pools_web_client_id: '1d57628rvvnklh3tb9om4j2hql',
  oauth: {
    domain: 'login.redream.ai',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:8000/signin/',
    redirectSignOut: 'http://localhost:8000/signin/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://lftwsztdcvddpcnmoiresso5va.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AWS_IAM',
};

let envStack = getEnvConfig();

// console.log(envStack);

let CDKConfig = {
  ...awsDefaults,
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing:
    envStack?.local_http_connect ?? false,
  aws_appsync_graphqlEndpoint: envStack.aws_appsync_graphql_endpoint,
  aws_appsync_region: envStack.aws_appsync_region,
  aws_cognito_identity_pool_id: envStack.aws_cognito_identity_pool_id,
  aws_user_pools_id: envStack.aws_user_pools_id,
  aws_user_pools_web_client_id: envStack.aws_user_pools_web_client_id,
  oauth: {
    ...awsDefaults.oauth,
    domain: envStack.redream_auth_domain,
    redirectSignIn: envStack.redirect_signin,
    redirectSignOut: envStack.redirect_signout,
  },
};

// console.log(CDKConfig);

if (envStack.debug_logs !== true && !process.env.LOCAL_TESTING) {
  if (!window.console) window.console = {};
  var methods = ['log', 'debug', 'warn', 'info'];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

console.log(`testing? ${process.env.LOCAL_TESTING}`);
// console.log(`endpoint: ${process.env.LOCAL_APPSYNC_ENDPOINT}`);
// if (process.env.ENV === 'true') {
//   // Can be better, but for testing this is how I switch for now.
//   CDKConfig.aws_appsync_graphqlEndpoint = process.env.LOCAL_APPSYNC_ENDPOINT; //'http://localhost:3000/graphql'; // override to :3000
//   CDKConfig.oauth.redirectSignIn = process.env.LOCAL_REDIRECT_URL;
//   CDKConfig.oauth.redirectSignOut = process.env.LOCAL_REDIRECT_URL;
// }

Amplify.configure(CDKConfig);

export const wrapPageElement = wrap;
