import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

// 3. extend the theme
// const theme = extendTheme({ config });
const theme = extendTheme({
  ...config,
  breakPoints: { sm: '40em', md: '52em', lg: '64em', xl: '80em' },
});

export default theme;

// export const myNewTheme = extendTheme({
//   colors: {
//     primary: '#845EC2',
//     secondary: '#D65DB1',
//     highlight: '#F9F871',
//     warning: '#FFC75F',
//     danger: '#FF6F91',
//   },
// });
