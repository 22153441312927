/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: true,
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id:
    'us-west-2:a10e7e70-ab96-4a01-9563-620f7cecb31a',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_V1tILs4dv', //'us-west-2_KVZpn4mm3',
  aws_user_pools_web_client_id: '1d57628rvvnklh3tb9om4j2hql',
  oauth: {
    domain: 'login.redream.ai',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:8000/signin/',
    redirectSignOut: 'http://localhost:8000/signin/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://lftwsztdcvddpcnmoiresso5va.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AWS_IAM',
};

export default awsmobile;
