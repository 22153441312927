import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  Link,
  IconButton,
  Slide,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { isBrowser } from '../../utils/configUtils';
import CookieConsent, { Cookies } from 'react-cookie-consent';

// const CookieBanner = () => {
//   const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
//   const location = useLocation();

//   useEffect(() => {
//     const cookieConsent = localStorage.getItem('cookieConsent');

//     if (!cookieConsent) {
//       console.log('cookie consent not set');
//       onToggle();
//     }
//   }, []);

//   const handleAgree = () => {
//     localStorage.setItem('cookieConsent', 'true');
//     // in your cookie banner

//     Cookies.set('gatsby-gdpr-google-analytics', 'true');
//     Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
//     Cookies.set('gatsby-gdpr-tiktok-pixel', 'true');

//     initializeAndTrack(location);
//     onToggle();
//   };

//   const handleClose = () => {
//     localStorage.setItem('cookieConsent', 'false');
//     if (isBrowser()) window[`ga-disable-G-3CDSX3TZ61`] = false;
//     onToggle();
//   };

//   return (
//     <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
//       <Box
//         position="relative"
//         bottom="0"
//         width="100%"
//         bg="gray.600"
//         color="white"
//         p={4}
//       >
//         <Center w={'full'}>
//           <HStack spacing={4}>
//             <Text fontSize={'lg'}>
//               We use cookies to improve your experience. By using our website,
//               you agree to our use of cookies outlined in our{' '}
//               <Link href="/legal/privacy-policy" color={'blue.500'}>
//                 Privacy Policy
//               </Link>
//               .
//             </Text>
//             <Button
//               colorScheme="teal"
//               onClick={handleAgree}
//               minW={'30px'}
//               p={'25px'}
//             >
//               I Agree
//             </Button>
//           </HStack>
//         </Center>
//         <IconButton
//           icon={<CloseIcon />}
//           position="absolute"
//           top="0"
//           right={'0'}
//           variant={'ghost'}
//           color="white"
//           p={4}
//           boxSize={'15px'}
//           aria-label={'close cookie banner'}
//           onClick={handleClose}
//         />
//       </Box>
//     </Slide>
//   );
// };

const CookieBanner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');

    if (!cookieConsent) {
      console.log('cookie consent not set');
      setShow(true);
    } else {
      Cookies.set('gatsby-gdpr-google-analytics', 'true');
      Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
      Cookies.set('gatsby-gdpr-tiktok-pixel', 'true');
      setShow(false);
    }
  }, []);

  return (
    <>
      {show ? (
        <CookieConsent
          location="bottom"
          buttonText="I agree"
          cookieName="gatsby-gdpr-google-tagmanager"
          onAccept={() => {
            Cookies.set('gatsby-gdpr-google-analytics', 'true');
            Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
            Cookies.set('gatsby-gdpr-tiktok-pixel', 'true');
            localStorage.setItem('cookieConsent', 'true');
            setShow(false);
          }}
          style={{ background: '#3182CE' }}
          buttonStyle={{ color: '#4e503b', fontSize: '17px' }}
          expires={150}
        >
          <Text fontSize={'lg'}>
            We use cookies to improve your experience. By using our website, you
            agree to our use of cookies outlined in our{' '}
            <Link href="/legal/privacy-policy" color={'#B2F5EA'}>
              Privacy Policy
            </Link>
            .
          </Text>
        </CookieConsent>
      ) : (
        <></>
      )}
    </>
  );
};

export default CookieBanner;
