import { useContext } from 'react';
import { getStripeEndpoint } from './configUtils';
import { AppContext } from '../components/Navbar/NavbarWrapper';
import { User } from '../types/redreamTypes';
import { getAuthenticated, getSession } from './auth-helper';

export enum ProductType {
  FREE = 'FREE',
  PRO_MONTH = 'PRO_MONTH',
  PRO_YEAR = 'PRO_YEAR',
  BIZ_MONTH = 'BIZ_MONTH',
  BIZ_YEAR = 'BIZ_YEAR',
}

const getUserPlanToProduct = (plan: string) => {
  switch (plan) {
    case 'free':
      return ProductType.FREE;
    case 'pro-monthly':
      return ProductType.PRO_MONTH;
    case 'pro-annual':
      return ProductType.PRO_YEAR;
    case 'biz-monthly':
      return ProductType.BIZ_MONTH;
    case 'biz-annual':
      return ProductType.BIZ_YEAR;
    default:
      return '';
  }
};

export const getPlanStorage = (user: User) => {
  switch (user?.plan) {
    case 'free':
      return 50000;
    case 'pro-monthly':
      return 50000;
    case 'pro-annual':
      return 50000;
    case 'biz-monthly':
      return ' ∞';
    case 'biz-annual':
      return ' ∞';
    default:
      return 0;
  }
};

export const getPlanString = (user: User) => {
  if (!user) {
    return 'N/A';
  }
  let plan = user?.plan.split('-')[0];
  plan = plan.charAt(0).toUpperCase() + plan.slice(1);
  if (plan === 'Biz') {
    plan = 'Business';
  }
  return plan;
};

export const getStripeApi = async (method: string) => {
  const idToken = (await getSession()).getIdToken().getJwtToken();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
  };
  const stripeEndpoint = getStripeEndpoint();
  const response = await fetch(`${stripeEndpoint}/${method}`, requestOptions);
  const data = await response.json();
  return data;
};

const postStripeApi = async (method: string, body: any) => {
  const idToken = (await getSession()).getIdToken().getJwtToken();

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(body),
  };
  const stripeEndpoint = getStripeEndpoint();
  const response = await fetch(`${stripeEndpoint}/${method}`, requestOptions);
  const data = await response.json();
  return data;
};

export const openCustomerPortal = async (userId: string) => {
  if (!userId) {
    console.error(`No customer ID found for user: ${userId}`);
    // TODO: toast
  }

  const data = await postStripeApi('customer-portal', {
    userId: userId,
  }).catch((err) => {
    console.error(err);
    return;
  });

  console.log(JSON.stringify(data));
  window.location = data.url;
};

export const createCheckoutSession = async (
  product: ProductType,
  user: User,
  shouldLogIn?: () => void
) => {
  if (!user) {
    console.error(`checkout session: No user found, returning`);
    if (shouldLogIn) shouldLogIn();
    return;
  }
  if (getUserPlanToProduct(user.plan) === product) {
    console.log(`plan same as clicked product`);
    return;
  }
  console.log(`clicked plan with pricing id: ${product}`);

  const data = await postStripeApi('create-checkout-session', {
    product: product,
    userId: user.sub,
  }).catch((err) => {
    console.error(err);
    return;
  });

  console.log(JSON.stringify(data));
  window.location = data.url;
};
