import { Box, useMediaQuery } from '@chakra-ui/react';
import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { Nav } from './NavbarDarkSwitcher';
import genReducer, {
  genReducerBaseState,
} from '../../components/Generation/Reducers/genReducer';
import { GenState } from '../../components/Generation/Types/StateTypes';
import { User } from 'types/redreamTypes';
import { getAuthenticated, getGuestCredentials } from '../../utils/auth-helper';
import { getUserByIdHelper } from '../../utils/api-helper';
import { Script } from 'gatsby';
import { getMoosendId } from '../../utils/configUtils';
import CookieBanner from '../../components/Atoms/CookieBanner';

interface AppContextState {
  authUser?: any;
  setAuthUser: any;
  user?: User;
  setUser: any;
  studioState: GenState;
  studioDispatch: any;
  pageRef: any;
}

export const AppContext = createContext<AppContextState>({
  authUser: null,
  setAuthUser: null,
  user: null,
  setUser: null,
  studioState: null,
  studioDispatch: null,
  pageRef: null,
});

const NavbarWrapper = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  // const [studioState, setStudioState] = useState<GenState>(genReducerBaseState);
  const [studioState, studioDispatch] = useReducer(
    genReducer,
    genReducerBaseState
  );
  const pageRef = useRef<number>(0);

  useEffect(() => {
    const authChecks = async () => {
      try {
        const authUser = await getAuthenticated();
        setAuthUser(authUser);
        console.log(authUser);
        const userData = await getUserByIdHelper(authUser.attributes?.sub);
        console.log(`got user data in nav: ${JSON.stringify(userData)}`);
        const setUserData = {
          ...userData.data.getUserById,
          picture: authUser.attributes.picture,
        };
        console.log(`navWrapper: setUser: ${JSON.stringify(setUserData)}`);
        setUser(setUserData);
        if (setUserData?.plan === 'free') {
          studioDispatch({
            type: 'CHANGE_SAFETY_CHECKER_ENABLED',
            value: true,
          });
        }
        // if (onGetUserComplete) {
        //   onGetUserComplete(userData);
        // }
        return;
      } catch (e) {
        console.log(
          `Could not get authenticated user credentials: ${JSON.stringify(e)}`
        );
      }

      try {
        const anonymousUser = await getGuestCredentials();
        console.log(anonymousUser);
        return;
      } catch (e) {
        console.log(
          `An error occured getting guest credentials: ${JSON.stringify(e)}`
        );
      }
    };
    authChecks();
  }, []);

  return (
    <AppContext.Provider
      value={{
        authUser,
        setAuthUser,
        user,
        setUser,
        studioState,
        studioDispatch,
        pageRef,
      }}
    >
      <Box style={{ zIndex: 5 }} as="header" position="fixed" w="100%">
        <Nav />
        <CookieBanner />
      </Box>
      {children}
    </AppContext.Provider>
  );
};

export default NavbarWrapper;
