import { createReducer, updateObject } from '../../../utils/reducerUtils';
import { StudioPanelState } from '../Types/StateTypes';

export const studioReducerIniState: StudioPanelState = {
  // model_id: 'stable-diffusion-v1-5',
  // model_date: '2023-01-24',
  // model_type: 'DEFAULT',
  init_image: null,
  num_images: 1,
  image_input_open: false,
  parameters_open: false,
  advanced_open: false,
  storageWarningModalOpen: false,
  creditWarningModalOpen: false,
  animateModelWarningModalOpen: false,
  model_preferences_enabled: true,
  payload: {
    prompt: '',
    magic_prompt_enabled: true,
    safety_checker_enabled: false,
    animate_enabled: false,
    animate_smoothing: 0,
    neg_prompt:
      'Watermark, Text, censored, deformed, bad anatomy, disfigured, poorly drawn face, mutated, extra limb, ugly, poorly drawn hands, missing limb, floating limbs, disconnected limbs, disconnected head, malformed hands, long neck, mutated hands and fingers, bad hands, missing fingers, cropped, worst quality, low quality, mutation, poorly drawn, huge calf, bad hands, fused hand, missing hand, disappearing arms, disappearing thigh, disappearing calf, disappearing legs, missing fingers, fused fingers, abnormal eye proportion, Abnormal hands, abnormal legs, abnormal feet, abnormal fingers',
    denoise_strength: 0.5,
    scheduler: 'dpm++',
    width: 512,
    height: 512,
    num_inference_steps: 20,
    seed: '',
    guidance_scale: 7,
    embeddings: [],
  },
  user_modified_fields: {
    width: false,
    height: false,
    num_inference_steps: false,
    guidance_scale: false,
    scheduler: false,
    neg_prompt: false,
  },
};

const changeNumImages = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    num_images: action.value,
  });
};

const changePrompt = (txt2imgState, action) => {
  console.log(`changed prompt: ${action.value}`);
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      prompt: action.value,
    }),
  });
};

const changeMagicPromptEnabled = (txt2imgState, action) => {
  console.log(`changed magic prompt: ${action.value}`);
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      magic_prompt_enabled: action.value,
    }),
  });
};

const changeSafetyCheckerEnabled = (txt2imgState, action) => {
  console.log(`changed safety checker: ${action.value}`);
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      safety_checker_enabled: action.value,
    }),
  });
};

const changeAnimateEnabled = (txt2imgState, action) => {
  // If animate is enabled, clamp width and height to max of 768:
  let width = txt2imgState.payload.width;
  let height = txt2imgState.payload.height;
  let num_inference_steps = txt2imgState.payload.num_inference_steps;
  if (action.value) {
    width = Math.min(width, 768);
    height = Math.min(height, 768);
    num_inference_steps = Math.min(num_inference_steps, 40);
  }
  console.log(`changed animate enabled: ${action.value}`);
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      animate_enabled: action.value,
      width: width,
      height: height,
      num_inference_steps: num_inference_steps,
    }),
  });
};

const changeAnimateSmoothing = (txt2imgState, action) => {
  console.log(`changed animate smoothing: ${action.value}`);
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      animate_smoothing: action.value,
    }),
  });
};

const changeNegPrompt = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      neg_prompt: true,
      neg_embeddings: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      neg_prompt: action.value,
    }),
  });
};

const changeScheduler = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      scheduler: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      scheduler: action.value,
    }),
  });
};

const changeWidth = (txt2imgState, action) => {
  console.log(`changed width: ${action.value}`);

  // If animate is enabled, clamp width and height to max of 768:
  let width = action.value;
  if (txt2imgState.payload.animate_enabled) {
    width = Math.min(width, 768);
    console.log(`width clamped: ${width}`);
  }

  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      width: true,
      height: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      width: width,
    }),
  });
};

const changeHeight = (txt2imgState, action) => {
  // If animate is enabled, clamp width and height to max of 768:
  let height = action.value;
  if (txt2imgState.payload.animate_enabled) {
    height = Math.min(height, 768);
    console.log(`height clamped: ${height}`);
  }

  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      width: true,
      height: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      height: height,
    }),
  });
};

const changeInferenceSteps = (txt2imgState, action) => {
  // If animate is enabled, clamp inference_steps to max of 40:
  let num_inference_steps = action.value;
  if (txt2imgState.payload.animate_enabled) {
    num_inference_steps = Math.min(num_inference_steps, 40);
    console.log(`num_inference_steps clamped: ${num_inference_steps}`);
  }
  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      num_inference_steps: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      num_inference_steps: num_inference_steps,
    }),
  });
};

const changeCFGScale = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    user_modified_fields: updateObject(txt2imgState.user_modified_fields, {
      guidance_scale: true,
    }),
    payload: updateObject(txt2imgState.payload, {
      guidance_scale: action.value,
    }),
  });
};

const changeSeed = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      seed: action.value,
    }),
  });
};

const setStorageWarningModalOpen = (txt2imgState, action) => {
  console.log(`setting storage warning modal open: ${action.isOpen}`);
  return updateObject(txt2imgState, {
    storageWarningModalOpen: action.isOpen,
  });
};

const setCreditWarningModalOpen = (txt2imgState, action) => {
  console.log(`setting credit warning modal open: ${action.isOpen}`);
  return updateObject(txt2imgState, {
    creditWarningModalOpen: action.isOpen,
  });
};

const setAnimateModelWarningModalOpen = (txt2imgState, action) => {
  console.log(`setting animate model warning modal open: ${action.isOpen}`);
  return updateObject(txt2imgState, {
    animateModelWarningModalOpen: action.isOpen,
  });
};

const setImageInputOpen = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    image_input_open: action.isOpen,
  });
};

const setParametersOpen = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    parameters_open: action.isOpen,
  });
};

const setAdvancedOpen = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    advanced_open: action.isOpen,
  });
};

const setInitImage = (txt2imgState, action) => {
  console.log(`updating init image reducer: ${action.img}`);
  const updateState = updateObject(txt2imgState, {
    init_image: action.img,
  });
  console.log(`updated image reducer state: ${JSON.stringify(updateState)}`);
  return updateState;
};

const changeDenoiseStrength = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      denoise_strength: action.value,
    }),
  });
};

const setTxt2imgState = (genState, action) => {
  return updateObject(genState, action.state);
};

const setModelPreferencesEnabled = (txt2imgState, action) => {
  return updateObject(txt2imgState, {
    model_preferences_enabled: action.value,
  });
};

const addActiveEmbedding = (txt2imgState: StudioPanelState, action) => {
  console.log(`adding active embedding: ${JSON.stringify(action.value)}`);
  console.log(
    `current embeddings: ${JSON.stringify(txt2imgState.payload.embeddings)}`
  );

  let updatedNegEmbed = null;
  if (action.value.type === 'NEGATIVE') {
    updatedNegEmbed = action.value;
  }
  const concatEmbed = txt2imgState.payload.embeddings.concat([action.value]);

  console.log(`concat embeddings: ${JSON.stringify(concatEmbed)}`);

  let updatedUserModifiedFields = txt2imgState.user_modified_fields;
  if (updatedNegEmbed != null) {
    updatedUserModifiedFields = updateObject(
      txt2imgState.user_modified_fields,
      {
        neg_prompt: true,
      }
    );
  }

  return updateObject(txt2imgState, {
    user_modified_fields: updatedUserModifiedFields,
    payload: updateObject(txt2imgState.payload, {
      embeddings: concatEmbed,
    }),
  });
};

const updateActiveEmbedding = (txt2imgState: StudioPanelState, action) => {
  let updatedNegEmbed = null;
  const updatedEmbeds = txt2imgState.payload.embeddings.map((embedding) => {
    if (embedding.token === action.value.token) {
      // If embedding is negative, update user_modified_fields
      if (embedding.type === 'NEGATIVE') {
        updatedNegEmbed = embedding;
      }
      return action.value;
    }
    return embedding;
  });
  console.log(`updated embeddings: ${JSON.stringify(updatedEmbeds)}`);

  let updatedUserModifiedFields = txt2imgState.user_modified_fields;
  if (updatedNegEmbed != null) {
    updatedUserModifiedFields = updateObject(
      txt2imgState.user_modified_fields,
      {
        neg_prompt: true,
      }
    );
  }

  return updateObject(txt2imgState, {
    user_modified_fields: updatedUserModifiedFields,
    payload: updateObject(txt2imgState.payload, {
      embeddings: updatedEmbeds,
    }),
  });
};

const removeActiveEmbedding = (txt2imgState: StudioPanelState, action) => {
  console.log(`removing active embedding: ${JSON.stringify(action.value)}`);
  console.log(
    `current embeddings: ${JSON.stringify(txt2imgState.payload.embeddings)}`
  );

  let updatedNegEmbed = null;
  const embedRemoved = txt2imgState.payload.embeddings.filter((embedding) => {
    if (embedding.token !== action.value.token) {
      return true;
    } else {
      if (embedding.type === 'NEGATIVE') {
        updatedNegEmbed = embedding;
      }
      return false;
    }
  });

  let updatedUserModifiedFields = txt2imgState.user_modified_fields;
  if (updatedNegEmbed != null) {
    updatedUserModifiedFields = updateObject(
      txt2imgState.user_modified_fields,
      {
        neg_prompt: true,
      }
    );
  }
  console.log(`embedRemoved: ${JSON.stringify(embedRemoved)}`);
  return updateObject(txt2imgState, {
    user_modified_fields: updatedUserModifiedFields,
    payload: updateObject(txt2imgState.payload, {
      embeddings: embedRemoved,
    }),
  });
};

const setActiveEmbeddings = (txt2imgState: StudioPanelState, action) => {
  console.log(`set active embeddings: ${JSON.stringify(action.value)}`);

  return updateObject(txt2imgState, {
    payload: updateObject(txt2imgState.payload, {
      embeddings: action.value,
    }),
  });
};

const setUserModifiedFields = (txt2imgState: StudioPanelState, action) => {
  console.log(`set user modified fields: ${JSON.stringify(action.value)}`);
  return updateObject(txt2imgState, {
    user_modified_fields: action.value,
  });
};

export const studioPanelReducer: (state: any, action: any) => StudioPanelState =
  createReducer(studioReducerIniState, {
    CHANGE_NUM_IMAGES: changeNumImages,
    CHANGE_PROMPT: changePrompt,
    CHANGE_MAGIC_PROMPT_ENABLED: changeMagicPromptEnabled,
    CHANGE_SAFETY_CHECKER_ENABLED: changeSafetyCheckerEnabled,
    CHANGE_ANIMATE_ENABLED: changeAnimateEnabled,
    CHANGE_ANIMATE_SMOOTHING: changeAnimateSmoothing,
    CHANGE_NEG_PROMPT: changeNegPrompt,
    CHANGE_DENOISE_STRENGTH: changeDenoiseStrength,
    CHANGE_SCHEDULER: changeScheduler,
    CHANGE_WIDTH: changeWidth,
    CHANGE_HEIGHT: changeHeight,
    CHANGE_INFERENCE_STEPS: changeInferenceSteps,
    CHANGE_CFG_SCALE: changeCFGScale,
    CHANGE_SEED: changeSeed,
    SET_INIT_IMAGE: setInitImage,
    SET_STORAGE_WARNING_MODAL_OPEN: setStorageWarningModalOpen,
    SET_CREDIT_WARNING_MODAL_OPEN: setCreditWarningModalOpen,
    SET_ANIMATE_MODEL_WARNING_MODAL_OPEN: setAnimateModelWarningModalOpen,
    SET_PARAMETERS_OPEN: setParametersOpen,
    SET_IMAGE_INPUT_OPEN: setImageInputOpen,
    SET_ADVANCED_OPEN: setAdvancedOpen,
    SET_TXT2IMG_STATE: setTxt2imgState,
    SET_MODEL_PREFERENCES_ENABLED: setModelPreferencesEnabled,
    ADD_ACTIVE_EMBEDDING: addActiveEmbedding,
    UPDATE_ACTIVE_EMBEDDING: updateActiveEmbedding,
    REMOVE_ACTIVE_EMBEDDING: removeActiveEmbedding,
    SET_ACTIVE_EMBEDDINGS: setActiveEmbeddings,
    SET_USER_MODIFIED_FIELDS: setUserModifiedFields,
  });
