export const getUserById = /* GraphQL */ `
  query GetUserById($userSub: String!) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      plan
      subscriptionCurrentPeriodEnd
      follows
      imageCount
      expiringCredits
      nonExpiringCredits
      productUpdateEmails
      createdAt
      updatedAt
    }
  }
`;

export const getUserByIdWithImages = /* GraphQL */ `
  query GetUserById($userSub: String!, $first: Int, $after: String) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      plan
      subscriptionCurrentPeriodEnd
      follows
      imageCount
      expiringCredits
      nonExpiringCredits
      productUpdateEmails
      images(first: $first, after: $after) {
        totalCount
        edges {
          node {
            uuid
            jobId
            jobType
            name
            url
            fullSizeUrl
            params
            likes
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserByIdWithModels = /* GraphQL */ `
  query GetUserById($userSub: String!, $first: Int, $after: String) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      follows
      userModels(first: $first, after: $after) {
        totalCount
        edges {
          node {
            uuid
            name
            params
            likes
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserByIdWithImagesAndModels = /* GraphQL */ `
  query GetUserById(
    $userSub: String!
    $firstImages: Int
    $afterImage: String
    $firstModels: Int
    $afterModel: String
  ) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      follows
      images(first: $firstImages, after: $afterImage) {
        totalCount
        edges {
          node {
            uuid
            jobId
            jobType
            name
            url
            params
            likes
            user {
              sub
              name
              picture
            }
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
      userModels(first: $firstModels, after: $afterModel) {
        totalCount
        edges {
          node {
            uuid
            name
            params
            likes
            user {
              sub
              name
              picture
            }
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getInProgressTrainingJobsByUserDate = /* GraphQL */ `
  query GetInProgressTrainingJobsByUserDate(
    $sub: String!
    $afterDate: AWSDateTime!
  ) {
    getInProgressTrainingJobsByUserDate(userSub: $sub, after_date: $afterDate) {
      uuid
      createdAt
      status
      note
      params
    }
  }
`;

export const getGenerationJobByUserStatus = /* GraphQL */ `
  query GetGenerationJobByUserStatus($userSub: String!, $status: JobStatus!) {
    getGenerationJobByUserStatus(userSub: $userSub, status: $status) {
      uuid
      type
      params
      status
      note
      uploadImageURLs
      user {
        sub
        name
        picture
        imageCount
      }
      createdAt
      updatedAt
    }
  }
`;

export const listBaseModels = /* GraphQL */ `
  query ListBaseModels {
    listBaseModels {
      uuid
      planType
      modelType
      name
      description
      thumbnail
      authorName
      authorLink
      preference {
        prompt
        neg_prompt
        neg_style
        width
        height
        guidance
        quality
        scheduler
      }
      createdAt
      updatedAt
    }
  }
`;

export const listEmbeddings = /* GraphQL */ `
  query ListEmbeddings {
    listEmbeddings {
      token
      weight_name
      model_base
      type
      tag
      display_name
      description
      createdAt
      updatedAt
    }
  }
`;
