import { Box, BoxProps, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import Tappable from 'react-tappable';
import { toJSON } from '../../utils/jsonUtils';

interface CustomOnTapProps extends BoxProps {
  onTap: (e) => void;
  onLongPress?: (e) => void;
  onRelease?: (e) => void;
  isDisabled?: boolean;
}

function calculateDistance(point1, point2) {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;

  const distance = Math.sqrt(dx * dx + dy * dy);
  return distance;
}

export const CustomOnTap = ({
  onTap,
  onLongPress,
  onRelease,
  isDisabled = false,
  children,
  ...props
}: CustomOnTapProps) => {
  // const config = {
  //   delta: 100, // min distance(px) before a swipe starts. *See Notes*
  //   preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
  //   trackTouch: true, // track touch input
  //   trackMouse: false, // track mouse input
  //   rotationAngle: 0, // set a rotation angle
  //   swipeDuration: 220, // allowable duration of a swipe (ms). *See Notes*
  //   touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  // };
  // const handlers = useSwipeable({
  //   onTap: (eventData) => {
  //     console.log(`custom on tap`);
  //     onTap();
  //   },
  //   ...config,
  // });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [startTime, setStartTime] = useState(0);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [didLongPress, setDidLongPress] = useState(false);
  const maxTapTimeDelta = 300;
  const maxTapSpeedDelta = 0.05;
  const longPressTime = 500; // time in milliseconds for a long press
  return (
    <Box
      p={0}
      m={0}
      onTouchStart={(e) => {
        if (isDisabled) return;
        const curTime = new Date().getTime();
        setStartTime(curTime);
        var touch = e.touches[0];
        setStartPos({ x: touch.pageX, y: touch.pageY });
        console.log(
          `touch started ${curTime} x pos: ${touch.pageX} y pos: ${touch.pageY}`
        );

        // Set a timeout for the long press
        onLongPress &&
          setLongPressTimeout(
            setTimeout(() => {
              console.log(`long press timeout`);
              onLongPress(e);
              setDidLongPress(true);
            }, longPressTime)
          );
      }}
      onTouchEnd={
        isMobile
          ? (e) => {
              if (isDisabled) return;
              // Clear the long press timeout
              if (onLongPress) {
                clearTimeout(longPressTimeout);
                setLongPressTimeout(null);
                if (didLongPress) {
                  setDidLongPress(false);
                  onRelease && onRelease(e);
                  return;
                }
              }

              const curTime = new Date().getTime();
              console.log(`tapping time check = ${curTime - startTime}`);
              const timeDelta = curTime - startTime;
              if (timeDelta > maxTapTimeDelta) return;

              var touch = e.touches[0] || e.changedTouches[0];
              const endPos = { x: touch.pageX, y: touch.pageY };

              const tapSpeed =
                calculateDistance(startPos, endPos) /
                (timeDelta === 0 ? 1 : timeDelta);
              console.log(`tapping time speed check = ${tapSpeed}`);
              if (tapSpeed > maxTapSpeedDelta) return;
              onTap(e);
            }
          : null
      }
      {...props}
    >
      {/* <Tappable
        p={0}
        m={0}
        pressDelay={150}
        // onTap={
        //   isMobile
        //     ? () => {
        //         const curTime = new Date().getTime();
        //         console.log(`tapping time check = ${curTime - startTime}`);

        //         if (curTime - startTime > maxTapTimeDelta) return;
        //         onTap();
        //       }
        //     : null
        // }
        {...props}
      > */}
      <Flex
        p={0}
        m={0}
        onClick={isMobile ? null : (e) => !isDisabled && onTap(e)}
      >
        {children}
      </Flex>
      {/* </Tappable> */}
    </Box>
  );
};
