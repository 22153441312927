import { ChakraProvider } from '@chakra-ui/react';
// import { theme } from './src/@chakra-ui/gatsby-plugin/theme';
import React from 'react';
import NavbarWrapper from './src/components/Navbar/NavbarWrapper';

export const wrapPageElement = ({ element, props }) => {
  return (
    <ChakraProvider resetCSS>
      <NavbarWrapper {...props}>{element}</NavbarWrapper>
    </ChakraProvider>
  );
};
