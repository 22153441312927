import { Auth } from 'aws-amplify';
import { User } from 'types/redreamTypes';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// async function isAuthenticated() {
//   try {
//     let user = await Auth.currentAuthenticatedUser();
//     return user;
//   } catch {
//     return false;
//   }
// }

export type AuthMode =
  | 'AWS_IAM'
  | 'API_KEY'
  | 'OPENID_CONNECT'
  | 'AMAZON_COGNITO_USER_POOLS'
  | 'AWS_LAMBDA';

export const getCurrentAuthMode = () => {
  return (async () => {
    try {
      await Auth.currentUserPoolUser();
      return 'AMAZON_COGNITO_USER_POOLS';
    } catch {
      return 'AWS_IAM';
    }
  })();
};

export const getAuthenticated = async () => {
  return Auth.currentAuthenticatedUser();
};

export const getSession = async () => {
  return Auth.currentSession();
};

export const getGuestCredentials = async () => {
  return Auth.currentCredentials();
};

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export const googleProvider = CognitoHostedUIIdentityProvider.Google;
