import { API } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import { listImagesByUser } from '../graphql/queries';
import { deleteImage, deleteImages } from '../graphql/mutations';
import { subscribeToCreateImage } from '../graphql/subscriptions';
import {
  getInProgressTrainingJobsByUserDate,
  getUserById,
  getUserByIdWithImages,
  getUserByIdWithImagesAndModels,
  getUserByIdWithModels,
  getGenerationJobByUserStatus,
  listBaseModels,
  listEmbeddings,
} from '../customGraphql/queries';
import {
  createTrainingJob,
  startTraining,
  createGenerationJob,
  startGeneration,
  cancelGeneration,
  updateUserCommunications,
} from '../customGraphql/mutations';
import {
  subscribeToUpdateTrainingJob,
  subscribeToUpdateGenerationJob,
} from '../customGraphql/subscriptions';
import { AuthMode, getCurrentAuthMode } from './auth-helper';
import {
  CollectionID,
  GenerationJob,
  TrainingJob,
  TrainingJobInput,
  User,
  Image,
  JobStatus,
  GenerationJobInput,
  BaseModel,
  Embedding,
} from '../API';

const graphqlHelper = (query: string, variables: any, authMode?: AuthMode) => {
  return new Promise(async (resolve, reject) => {
    const currentAuthMode = await getCurrentAuthMode();
    console.log({ authMode: currentAuthMode });
    try {
      const result = await API.graphql({
        query,
        variables,
        authMode: authMode ?? currentAuthMode,
      });
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

const subscriptionHelper = (
  query: string,
  variables: any,
  onRecieve: any,
  onError?: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const authMode = await getCurrentAuthMode();
      const subscription = await API.graphql<GraphQLSubscription<any>>({
        query,
        variables,
        authMode: authMode,
      });
      const res = (subscription as any).subscribe({
        next: ({ provider, value }) => {
          console.log({ provider, value });
          onRecieve(value);
        },
        error: (error) => {
          console.warn(error);
          if (onError != null) {
            onError(error);
          }
        },
      });

      console.log(`Subscription value: ${JSON.stringify(res)}`);

      resolve(res);
    } catch (e) {
      reject(e);
      throw new Error(`Could not subscribe: ${e}`);
    }
  });
};

// export const describeImageHelper = async (InitImage: Image) => {
//   const result: any = await graphqlHelper(getUserById, {
//     id: InitImage.owner,
//   });
//   return result.data.getUserById as User;
// }

export const listBaseModelsHelper = async () => {
  const result: any = await graphqlHelper(listBaseModels, null);
  return result.data.listBaseModels as BaseModel[];
};

export const listEmbeddingsHelper = async () => {
  const result: any = await graphqlHelper(listEmbeddings, null);
  return result.data.listEmbeddings as Embedding[];
};

export const pullImagesHelper = async (userSub) => {
  return graphqlHelper(listImagesByUser, { userSub });
};

export const deleteImageHelper = async (imgID: CollectionID) => {
  console.log(`given id: ${JSON.stringify(imgID)}`);

  // const result: any = await graphqlHelper(deleteImage, {
  //   id: imgID,
  // });
  // return result.data.deleteImage as string;
  const result: any = await graphqlHelper(deleteImages, {
    ids: [imgID],
  });
  return result.data.deleteImages as string;
};

export const deleteImagesHelper = async (imgIDs: CollectionID[]) => {
  console.log(`given ids: ${JSON.stringify(imgIDs)}`);

  const result: any = await graphqlHelper(deleteImages, {
    ids: imgIDs,
  });
  return result.data.deleteImages as string;
};

export const updateUserCommunicationsHelper = async (
  userSub: string,
  productUpdateEmails: boolean
) => {
  console.log(`productUpdateEmails: ${JSON.stringify(productUpdateEmails)}`);

  const result: any = await graphqlHelper(updateUserCommunications, {
    updateUserCommunications: { sub: userSub, productUpdateEmails },
  });
  return result.data.updateUserCommunications as User;
};

export const getUserByIdHelper = async (userSub): Promise<any> => {
  return graphqlHelper(getUserById, { userSub });
};

export const getUserByIdWithImagesHelper = async (
  userSub: string,
  first: number,
  after?: string
) => {
  const result: any = await graphqlHelper(getUserByIdWithImages, {
    userSub,
    first,
    after,
  });

  return result.data.getUserById as User;
};

export const getUserByIdWithModelsHelper = async (
  userSub: string,
  first: number,
  after?: string
) => {
  const result: any = await graphqlHelper(getUserByIdWithModels, {
    userSub,
    first,
    after,
  });
  return result.data.getUserById as User;
};

export const getUserByIdWithImagesAndModelsHelper = async (
  userSub: string,
  firstImages: number,
  afterImage: string | undefined,
  firstModels: number,
  afterModel: string | undefined
) => {
  const result: any = await graphqlHelper(getUserByIdWithImagesAndModels, {
    userSub,
    firstImages,
    afterImage,
    firstModels,
    afterModel,
  });
  return result.data.getUserById as User;
};

export const getInProgressTrainingJobsByUserDateHelper = async (
  userSub: string,
  afterDate: string
) => {
  const result: any = await graphqlHelper(getInProgressTrainingJobsByUserDate, {
    sub: userSub,
    afterDate,
  });

  return result.data.getInProgressTrainingJobsByUserDate;
};

export const getGenerationJobByUserStatusHelper = async (
  userSub: string,
  status: JobStatus
): Promise<GenerationJob[]> => {
  const result: any = await graphqlHelper(getGenerationJobByUserStatus, {
    userSub: userSub,
    status: status,
  });

  return result.data.getGenerationJobByUserStatus;
};

export const createTrainingJobHelper = async (
  trainingJobInput: TrainingJobInput
) => {
  const result: any = await graphqlHelper(createTrainingJob, {
    trainingJob: trainingJobInput,
  });
  return result.data.createTrainingJob as TrainingJob;
};

export const startTrainingHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(startTraining, { id: jobID });
  return result.data.startTraining as TrainingJob;
};

export const createGenerationJobHelper = async (
  jobData: GenerationJobInput
) => {
  const genJob = {
    ...jobData,
    params:
      typeof jobData.params === 'string'
        ? jobData.params
        : JSON.stringify(jobData.params),
  }; // TODO
  const result: any = await graphqlHelper(createGenerationJob, {
    generationJob: genJob,
  });
  return result.data.createGenerationJob as GenerationJob;
};

export const startGenerationHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(startGeneration, { id: jobID });
  return result.data.startGeneration as GenerationJob;
};

export const cancelGenerationHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(cancelGeneration, { id: jobID });
  return result.data.cancelGeneration as string;
};

export const subscribeToCreateImageHelper = async (
  jobId: string,
  onRecieve: any,
  onError?: any
) => {
  await subscriptionHelper(
    subscribeToCreateImage,
    { jobId },
    onRecieve,
    onError
  );
};

export const subscribeToUpdateGenerationJobHelper = async (
  uuid: string,
  onRecieve: any,
  onError?: any
) => {
  return await subscriptionHelper(
    subscribeToUpdateGenerationJob,
    { uuid },
    onRecieve,
    onError
  );
};

export const subscribeToUpdateTrainingJobHelper = async (
  uuid: string,
  onRecieve: any,
  onError?: any
) => {
  const result: any = await subscriptionHelper(
    subscribeToUpdateTrainingJob,
    { uuid },
    onRecieve,
    onError
  );
  console.log(`subscribe to update training: ${JSON.stringify(result)}`);

  return result?.data?.subscribeToUpdateTrainingJob;
};
