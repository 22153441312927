import { useRef } from 'react';
import { createReducer, updateObject } from '../../../utils/reducerUtils';
import { ImgBrowserState } from '../Types/StateTypes';

export const imgBrowserIniState: ImgBrowserState = {
  loadedImgs: [],
  skeletonImgs: [],
  hasNextPage: true,
  imagesLoading: false,
  imageModalOpen: false,
};

const startLoadingImages = (imgBrowserState) => {
  return updateObject(imgBrowserState, {
    imagesLoading: true,
  });
};

const finishLoadingImages = (imgBrowserState) => {
  console.log(`imgBrowserReducer: finishLoadingImages`);

  return updateObject(imgBrowserState, {
    imagesLoading: false,
  });
};

const setEndCursor = (imgBrowserState, action) => {
  console.log(`Setting end cursor: ${action.endCursor}`);

  return updateObject(imgBrowserState, {
    hasNextPage: action.hasNextPage,
    endCursor: action.endCursor,
  });
};

const selectImage = (imgBrowserState, action) => {
  console.log(`select image state: ${JSON.stringify(action.img)}`);
  return updateObject(imgBrowserState, {
    selectedImg: action.img,
  });
};

const addLoadedImage = (imgBrowserState, action) => {
  const updatedImgs = updateObject(imgBrowserState, {
    loadedImgs: [action.img].concat(imgBrowserState.loadedImgs),
  });
  console.log(`added loaded img: ${JSON.stringify(updatedImgs)}`);

  return updatedImgs;
};

const removeLoadedImage = (imgBrowserState, action) => {
  const updatedImgs = updateObject(imgBrowserState, {
    loadedImgs: imgBrowserState.loadedImgs.filter((img) => {
      return img.uuid !== action.img.uuid;
    }),
    fakeRender: Math.random(),
  });
  console.log(
    `removed loaded image, fakeRender: ${JSON.stringify(
      updatedImgs.fakeRender
    )}`
  );

  return updatedImgs;
};

const setLoadedImages = (imgBrowserState, action) => {
  const after = updateObject(imgBrowserState, {
    loadedImgs: action.imgs,
  });

  return after;
};

const updateLoadedImages = (imgBrowserState, action) => {
  // console.log(
  //   `updating loaded images: ${JSON.stringify(
  //     imgBrowserState.loadedImgs.slice(0, 3)
  //   )}`
  // );

  const after = updateObject(imgBrowserState, {
    loadedImgs: imgBrowserState.loadedImgs.concat(action.imgs),
  });

  // console.log(
  //   `after updating loaded images: ${JSON.stringify(
  //     after.loadedImgs.slice(0, 3)
  //   )}`
  // );

  return after;
};

const trimLoadedImages = (imgBrowserState, action) => {
  console.log(`trimming loaded images`);
  console.log(`cur length: ${imgBrowserState.loadedImgs.length}`);
  let endCursor = imgBrowserState.endCursor;
  console.log(`cur end cursor: ${endCursor}`);
  if (imgBrowserState.loadedImgs.length > action.size) {
    endCursor = imgBrowserState.loadedImgs[action.size - 1].cursor;
    console.log(`updated end cursor: ${endCursor}`);
  }
  const after = updateObject(imgBrowserState, {
    loadedImgs: imgBrowserState.loadedImgs.slice(0, action.size),
    endCursor: endCursor,
    hasNextPage: true,
    imagesLoading: false,
  });
  console.log(`new loaded imgs length: ${after.loadedImgs.length}`);
  return after;
};

const addSkeletonImage = (imgBrowserState, action) => {
  const curTime = Date.now();
  return {
    ...imgBrowserState,
    skeletonImgs: [{ skel: action.id, startTime: curTime }].concat(
      imgBrowserState.skeletonImgs
    ),
  };
};

const removeSkeletonImage = (imgBrowserState) => {
  return {
    ...imgBrowserState,
    skeletonImgs: imgBrowserState.skeletonImgs.filter(
      (_, i) => i !== imgBrowserState.skeletonImgs.length - 1
    ),
  };
};

const removeAllSkeletonImages = (imgBrowserState) => {
  return {
    ...imgBrowserState,
    skeletonImgs: [],
    fakeRender: Math.random(),
  };
};

const refreshImageGrid = (imgBrowserState) => {
  return updateObject(imgBrowserState, {
    fakeRender: Math.random(),
  });
};

const setImageModalOpen = (imgBrowserState, action) => {
  console.log(`imgBrowserReducer: setImageModalOpen: ${action.isOpen}`);

  return updateObject(imgBrowserState, {
    imageModalOpen: action.isOpen,
  });
};

export const imgBrowserReducer = createReducer(imgBrowserIniState, {
  START_LOADING_IMAGES: startLoadingImages,
  FINISH_LOADING_IMAGES: finishLoadingImages,
  SET_END_CURSOR: setEndCursor,
  SELECT_IMAGE: selectImage,
  ADD_LOADED_IMAGE: addLoadedImage,
  REMOVE_LOADED_IMAGE: removeLoadedImage,
  SET_LOADED_IMAGES: setLoadedImages,
  UPDATE_LOADED_IMAGES: updateLoadedImages,
  TRIM_LOADED_IMAGES: trimLoadedImages,
  ADD_SKELETON_IMAGE: addSkeletonImage,
  REMOVE_SKELETON_IMAGE: removeSkeletonImage,
  REMOVE_ALL_SKELETON_IMAGES: removeAllSkeletonImages,
  REFRESH_IMAGE_GRID: refreshImageGrid,
  SET_IMAGE_MODAL_OPEN: setImageModalOpen,
});
