import { useEffect } from 'react';
import React from 'react';
import {
  Box,
  Flex,
  useColorModeValue,
  useColorMode,
  Image,
  Text,
  VStack,
  BoxProps,
  HStack,
} from '@chakra-ui/react';
import Link from '../Atoms/CustomLink';
import logoImgLight from '../../images/reDreamLogo_light.svg';
import { NavbarMobile } from './NavbarMobile';
import { NavbarDesktop } from './NavbarDesktop';

export const NAVBAR_HEIGHT = '64px';

export function Nav({ ...props }) {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'light') {
      // Because gatsby + chakra is dumb sometimes
      toggleColorMode();
    }
  }, []);

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.700')} {...props}>
        <Flex
          backdropFilter="auto"
          backdropBlur="9px"
          h={'64px'}
          px={5}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <HStack>
            <Link to="/">
              <Image src={logoImgLight} />
            </Link>
            <VStack>
              <Box h={'12px'} />
              <Text color={'gray.500'}>Beta</Text>
            </VStack>
          </HStack>

          <NavbarDesktop />
          <NavbarMobile />
        </Flex>
      </Box>
    </>
  );
}
