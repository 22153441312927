import React from 'react';
import {
  combineReducers,
  createReducer,
  updateObject,
} from '../../../utils/reducerUtils';
import { Action, BaseState, GenState, StudioTab } from '../Types/StateTypes';
import { imgBrowserIniState, imgBrowserReducer } from './imgBrowserReducer';
import {
  studioPanelReducer,
  studioReducerIniState,
} from './studioPanelReducer';
import { AppContext } from '../../../components/Navbar/NavbarWrapper';
// import { img2imgIniState, img2imgReducer } from './img2imgReducer';
// import { txt2imgIniState, txt2imgReducer } from './txt2imgReducer';

const baseReducerIniState: BaseState = {
  generateButtonDisabled: false,
  isLoading: false,
  // currentTab: StudioTab.TXT2IMG,
  error: '',
  loginModalOpen: false,
  selectedModel: {
    __typename: 'BaseModel',
    uuid: 'stable-diffusion-v1-5',
    planType: 'FREE',
    modelType: 'BASE',
    name: 'Stable Diffusion 1.5',
    description: 'The Stable Diffusion 1.5 Model',
    thumbnail: '',
    authorName: 'StabilityAI',
    authorLink: 'https://stability.ai/',
    createdAt: '2023-04-21T07:19:00.430Z',
    updatedAt: '2023-04-21T07:19:00.430Z',
  },
};

const changeTab = (genState, action) => {
  return updateObject(genState, {
    currentTab: action.tab,
  });
};

const startGeneration = (genState, action) => {
  console.log(`start generation called`);

  return updateObject(genState, {
    generateButtonDisabled: true,
    isLoading: true,
    jobType: action.jobType,
  });
};

const finishGeneration = (genState) => {
  return updateObject(genState, {
    generateButtonDisabled: false,
    isLoading: false,
    jobType: null,
  });
};

const setLoadedBaseModels = (genState, action) => {
  return updateObject(genState, {
    loadedModels: action.models,
  });
};
const setLoadedEmbeddings = (genState, action) => {
  return updateObject(genState, {
    loadedEmbeddings: action.embeddings,
  });
};

const selectModel = (genState, action) => {
  console.log(`selecting model reducer ${action.model.name}`);

  return updateObject(genState, {
    selectedModel: action.model,
  });
};

const setLoginModalOpen = (genState, action) => {
  return updateObject(genState, {
    loginModalOpen: action.isOpen,
  });
};

const baseReducer: (state: any, action: any) => BaseState = createReducer(
  baseReducerIniState,
  {
    START_GENERATION: startGeneration,
    FINISH_GENERATION: finishGeneration,
    CHANGE_TAB: changeTab,
    SET_LOADED_BASE_MODELS: setLoadedBaseModels,
    SET_LOADED_EMBEDDINGS: setLoadedEmbeddings,
    SELECT_MODEL: selectModel,
    SET_LOGIN_MODAL_OPEN: setLoginModalOpen,
  }
);

export const genReducerBaseState = {
  ...baseReducerIniState,
  txt2img: studioReducerIniState,
  // img2img: img2imgIniState,
  imgBrowser: imgBrowserIniState,
};

export default function genReducer(state: GenState, action: Action): GenState {
  return {
    ...baseReducer(state, action),
    txt2img: studioPanelReducer(state.txt2img, action),
    // img2img: img2imgReducer(state.img2img, action, state.currentTab),
    imgBrowser: imgBrowserReducer(state.imgBrowser, action),
  };
}
