import prod from '../cdk-exports-prod.json';
import test from '../cdk-exports-test.json';
import local from '../cdk-exports-local.json';
import deployTarget from '../../config/deploy-target.json';

export const isBrowser = () => typeof window !== 'undefined';

export type EnvConfig = {
  redream_root_domain: string;
  redream_auth_domain: string;
  redirect_signin: string;
  redirect_signout: string;
  aws_appsync_graphql_endpoint: string;
  aws_appsync_region: string;
  redream_data_bucket: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_cognito_identity_pool_id: string;
  local_http_connect?: boolean;
  debug_logs?: boolean;
  stripe_api_endpoint?: string;
  moosend_id?: string;
};

const convertConfig = (envStack: any): EnvConfig => {
  return {
    redream_root_domain: envStack.redreamrootdomain,
    redream_auth_domain: envStack.redreamauthdomain,
    redirect_signin: envStack.redirectsignin,
    redirect_signout: envStack.redirectsignout,
    aws_appsync_graphql_endpoint: envStack.awsappsyncgraphqlendpoint,
    aws_appsync_region: envStack.awsappsyncregion,
    redream_data_bucket: envStack.redreamdatabucket,
    aws_user_pools_id: envStack.awsuserpoolsid,
    aws_user_pools_web_client_id: envStack.awsuserpoolswebclientid,
    aws_cognito_identity_pool_id: envStack.awscognitoidentitypoolid,
    local_http_connect: envStack.localhttpconnect,
    debug_logs: envStack.debuglogs,
    stripe_api_endpoint: envStack.redreamstripeendpointurl,
    moosend_id: envStack.moosendid,
  };
};

export const getEnvConfig = (): EnvConfig => {
  let curEnv = process.env.ENV ?? deployTarget.deployment.target;
  let envStack;
  switch (curEnv) {
    case 'local':
      envStack = local.localStack;
      break;
    case 'test':
      envStack = test.testStack;
      break;
    case 'prod':
      envStack = prod.prodStack;
      break;
    default:
      throw new Error('No environment specified!');
  }
  if (process.env.ENV) {
    return {
      ...convertConfig(envStack),
      redirect_signin: local.localStack.redirectsignin,
      redirect_signout: local.localStack.redirectsignout,
    };
  }
  return convertConfig(envStack);
};

export const getMoosendId = () => {
  return getEnvConfig().moosend_id;
};

export const getS3DataBucket = () => {
  return getEnvConfig().redream_data_bucket;
};

export const getS3Endpoint = () => {
  if (process.env.ENV === 'local') {
    return 'http://localhost:9090';
  }
  return undefined;
};

export const getStripeEndpoint = () => {
  const env = getEnvConfig();
  if (env.stripe_api_endpoint) {
    const endpoint = env.stripe_api_endpoint;
    const endpoint_trim = endpoint.endsWith('/')
      ? endpoint.slice(0, -1)
      : endpoint;
    return endpoint_trim;
  }
  return `https://${env.redream_root_domain}`;
};
