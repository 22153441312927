import * as React from 'react';
import { BoxProps, Text } from '@chakra-ui/react';

interface HTextProps extends BoxProps {
  children: React.ReactNode[] | React.ReactNode;
}

const H1Text = ({ children, ...props }: HTextProps) => {
  return (
    <Text
      style={{ userSelect: 'none' }}
      fontSize={18}
      fontWeight={'semibold'}
      {...props}
    >
      {children}
    </Text>
  );
};
const H2Text = ({ children, ...props }: HTextProps) => {
  return (
    <Text
      style={{ userSelect: 'none' }}
      color="#A0a0a0"
      fontSize={15}
      fontWeight={'normal'}
      {...props}
    >
      {children}
    </Text>
  );
};

export { H1Text, H2Text };
