import { useEffect, useState } from 'react';
import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Center,
  Text,
  VStack,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';
import { signOut } from '../../utils/auth-helper';
import { User } from 'types/redreamTypes';
import { H1Text, H2Text } from '../../components/Atoms/TextComponents';
import { getPlanStorage, getPlanString } from '../../utils/stripeUtils';

interface NavbarMenuListProps {
  user: User;
  onSignOut: () => void;
}

export default function NavbarMenuList({
  user,
  onSignOut,
}: NavbarMenuListProps) {
  useEffect(() => {
    console.log(`NavbarMenuList user: ${JSON.stringify(user)}`);
  }, [user]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}
      >
        <Avatar
          referrerPolicy="no-referrer"
          size={'sm'}
          src={user?.picture ?? ''}
        />
      </MenuButton>
      <MenuList alignItems={'center'}>
        <br />
        <Center>
          <Avatar
            referrerPolicy="no-referrer"
            size={'2xl'}
            src={user?.picture ?? ''}
          />
        </Center>
        <VStack py={3} spacing={0}>
          <H1Text pb={'5px'}>{user?.name ?? ''}</H1Text>
          <H2Text>Current Plan: {getPlanString(user)}</H2Text>
          <H2Text>
            Pro Credits:{' '}
            {getPlanString(user) === 'Free' ? user?.expiringCredits : '∞'}
          </H2Text>
        </VStack>
        <MenuDivider />

        <MenuItem
          onClick={() => {
            navigate('/gallery', {
              // state: {
              //   user: {
              //     sub: user?.attributes.sub ?? '',
              //     name: user?.attributes.name ?? '',
              //     picture: user?.attributes.picture ?? '',
              //   },
              // },
            });
          }}
        >
          My Gallery
        </MenuItem>

        <MenuItem onClick={() => navigate('/account')}>
          Account Settings
        </MenuItem>

        <MenuItem onClick={() => navigate('/legal/privacy-policy/')}>
          Privacy
        </MenuItem>

        {/* <MenuItem>Account Settings</MenuItem> */}
        <MenuItem
          onClick={async () => {
            console.log('Logging out');
            try {
              await signOut();
              onSignOut();
            } catch {
              console.log('error signing out');
            }
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
