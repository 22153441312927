export const subscribeToUpdateGenerationJob = /* GraphQL */ `
  subscription SubscribeToUpdateGenerationJob($uuid: String!) {
    subscribeToUpdateGenerationJob(uuid: $uuid) {
      uuid
      type
      params
      status
      note
      user {
        sub
        name
        picture
        imageCount
        expiringCredits
        nonExpiringCredits
      }
      createdAt
      updatedAt
    }
  }
`;

export const subscribeToUpdateTrainingJob = /* GraphQL */ `
  subscription SubscribeToUpdateTrainingJob($uuid: String!) {
    subscribeToUpdateTrainingJob(uuid: $uuid) {
      status
      uuid
      note
      createdAt
    }
  }
`;
